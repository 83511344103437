// long live the IE
require('custom-event-polyfill');
window.Promise = require('bluebird');

if (!window.URLSearchParams) {
    require('url-search-params-polyfill');
}

URLSearchParams.prototype.toPairs = function () {
    let entries = this.toString().split('&');

    return entries.map(function (entry) {
        let pairs = entry.split('=');
        return [
            pairs[0] || null,
            pairs[1] || null,
        ];
    });
}

URLSearchParams.prototype.toStringClean = function () {
    let entries = [];
    this.toPairs().forEach(function(entry) {
        if (entry[1] && entry[1] != 'null' && entry[1] != 'undefined') {
            entries.push(entry);
        }
    })

    return (new URLSearchParams(entries)).toString();
}

if (!Object.entries) {
  Object.entries = function( obj ){
    var ownProps = Object.keys( obj ),
        i = ownProps.length,
        resArray = new Array(i); // preallocate the Array
    while (i--)
      resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
  };
}

if (typeof Object.assign != 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target, varArgs) { // .length of function is 2
      'use strict';
      if (target == null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      var to = Object(target);

      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];

        if (nextSource != null) { // Skip over if undefined or null
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}
